import React, { useRef } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { signIn } from "@http/AuthRepository"
import { setToken } from "@http/client"
import {
  detectMob,
  useInput,
  isInputEmpty,
  isPasswordVisible,
  isEmptyObject,
} from "@src/Helper"
import LoginInputEmail from "./LoginInputEmail"
import LoginInputPassword from "./LoginInputPassword"
import { AlertError } from "@components/utils/Alert"

const Login = () => {
  // Hooks
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const eyeRef = useRef(null)
  const email = useInput("")
  const password = useInput("")
  const isLoading = useInput(false)
  const error = useInput({})
  const emailPasswordError = useInput("")

  // Custom Functions
  const handleSignIn = () => {
    error.setValue({ ...error.value })
    isLoading.setValue(true)
    signIn(email.value, password.value)
      .then(res => {
        setToken(res.data.access_token)
        error.setValue({})
        emailPasswordError.setValue("")
        navigate("/dashboard")
      })
      .catch(err => {
        if (err.response.data.status_code === 422) {
          emailPasswordError.setValue("")
          error.setValue({
            ...error.value,
            email: err.response.data.errors.email
              ? err.response.data.errors.email
              : null,
            password: err.response.data.errors.password
              ? err.response.data.errors.password
              : null,
          })
        } else if (err.response.data.status_code === 401) {
          error.setValue({})
          emailPasswordError.setValue(err.response.data.message)
        }
        isLoading.setValue(false)
      })
  }

  // JSX
  if (detectMob()) {
    return (
      <div id="m-login" className="login">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="login__title heading-3">Masuk ke Sociopipe</h1>
              {emailPasswordError.value !== "" && (
                <AlertError
                  className="flex-a-center"
                  text={<p className="body mb-0">{emailPasswordError.value}</p>}
                />
              )}
              <form
                className="login__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleSignIn()
                }}
              >
                <LoginInputEmail
                  emailRef={emailRef}
                  email={email}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                  autofocus={true}
                />
                <LoginInputPassword
                  passwordRef={passwordRef}
                  eyeRef={eyeRef}
                  password={password}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                  error={error}
                />
                <Link
                  className="login__form__forgot-password caption"
                  to="/forgot-password"
                >
                  Lupa Kata Sandi?
                </Link>
                <button
                  type="submit"
                  className="login__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Masuk</>
                  )}
                </button>
              </form>
              <p className="login__go-to__register body">
                Belum memiliki akun?{" "}
                <Link className="login__go-to__register__link" to="/register">
                  Daftar Sekarang
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="login">
        <div className="container pad-t-175">
          <div className="row">
            <div className="col-5">
              <h1 className="login__title heading-3">Masuk ke Sociopipe</h1>
              {emailPasswordError.value !== "" && (
                <AlertError
                  className="flex-a-center"
                  text={<p className="body mb-0">{emailPasswordError.value}</p>}
                />
              )}
              <form
                className="login__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleSignIn()
                }}
              >
                <LoginInputEmail
                  emailRef={emailRef}
                  email={email}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  error={error}
                  autofocus={true}
                />
                <LoginInputPassword
                  passwordRef={passwordRef}
                  eyeRef={eyeRef}
                  password={password}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                  error={error}
                />
                <Link
                  className="login__form__forgot-password caption"
                  to="/forgot-password"
                >
                  Lupa Kata Sandi?
                </Link>
                <button
                  type="submit"
                  className="login__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Masuk</>
                  )}
                </button>
              </form>
              <p className="login__go-to__register body">
                Belum memiliki akun?{" "}
                <Link className="login__go-to__register__link" to="/register">
                  Daftar Sekarang
                </Link>
              </p>
            </div>
            <div className="col-5 offset-2 position-relative">
              <div className="position-relative">
                <StaticImage
                  className="login__image"
                  src="../../../images/auth/auth-register-login.png"
                  alt="Login Image"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                />
                {/* <div className="login__quote">
                  <div className="triangle-shape"></div>
                  <div className="login__quote__box">
                    <h3 className="login__quote__box__text heading-5">
                      Create Your Own World With Sociopipe
                    </h3>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
