import * as React from "react"
import Login from "@components/Auth/Login/Login"
import Seo from "@components/seo"

const LoginPage = () => (
  <>
    <Seo title="Login" />
    <Login />
  </>
)

export default LoginPage
