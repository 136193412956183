import React from "react"
import { detectMob } from "@src/Helper"

const LoginInputPassword = ({
  passwordRef,
  eyeRef,
  password,
  isInputEmpty,
  isEmptyObject,
  isPasswordVisible,
  error,
}) => {
  if (detectMob()) {
    return (
      <div className="login__form__password">
        <label
          htmlFor="inputPassword"
          className="login__form__password__label label-sm"
        >
          Kata Sandi
        </label>
        <div className="position-relative">
          <input
            type="password"
            className="login__form__password__input input-md"
            id="inputPassword"
            spellCheck={false}
            placeholder="Masukkan kata sandi kamu"
            ref={passwordRef}
            onChange={e => {
              isInputEmpty(passwordRef)
              password.setValue(e.target.value)
            }}
          />
          <span
            role="button"
            aria-label="Show Password"
            tabIndex="0"
            className="login__form__password__show mdi mdi-eye-off"
            ref={eyeRef}
            onClick={() => isPasswordVisible(passwordRef, eyeRef)}
            onKeyDown={e => {
              if (e.key === "Enter") isPasswordVisible(passwordRef, eyeRef)
            }}
          ></span>
        </div>
        {isEmptyObject(error.value, error.value.password) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.password}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="login__form__password">
        <label
          htmlFor="inputPassword"
          className="login__form__password__label label-sm"
        >
          Kata Sandi
        </label>
        <div className="position-relative">
          <input
            type="password"
            className="login__form__password__input input-md"
            id="inputPassword"
            spellCheck={false}
            placeholder="Masukkan kata sandi kamu"
            ref={passwordRef}
            onChange={e => {
              isInputEmpty(passwordRef)
              password.setValue(e.target.value)
            }}
          />
          <span
            role="button"
            aria-label="Show Password"
            tabIndex="0"
            className="login__form__password__show mdi mdi-eye-off"
            ref={eyeRef}
            onClick={() => isPasswordVisible(passwordRef, eyeRef)}
            onKeyDown={e => {
              if (e.key === "Enter") isPasswordVisible(passwordRef, eyeRef)
            }}
          ></span>
        </div>
        {isEmptyObject(error.value, error.value.password) ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value.password}</span>
        )}
      </div>
    )
  }
}

export default LoginInputPassword
